import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { DiscussionEmbed } from 'disqus-react'
import Swiper from 'react-id-swiper'
import Helmet from 'react-helmet'

/* STYLES */
import './post-template.scss'

/* ICONS */
import facebook from '../images/blog/facebook.svg'
import linkedin from '../images/blog/linkedin.svg'
import twitter from '../images/blog/twitter.svg'
import pinterest from '../images/blog/pinterest.svg'
// import youtube from '../images/blog/youtube.svg'
import whatsapp from '../images/blog/whatsapp.svg'

const BlogHeader = (post) => {
  const metaTitle = post.metaTitle ? post.metaTitle : post.title
  const metaDescription = post.metaDescription ? post.metaDescription.metaDescription : post.description.description
  const metaImage = post.sharingCard ? 'https://www.lwart.com.br' + post.sharingCard.localFile.publicURL : 'https://www.lwart.com.br' + post.cardImage.localFile.publicURL

  return <>
    <Helmet>
      <title>{metaTitle} | Lwart</title>
      <meta name="title" content={`${metaTitle} | Lwart`} />
      <meta name="author" content="Lwart" />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={post.tags.join(', ')} />
      <meta name="description" content={`${metaDescription}`} />
      <meta property="og:title" content={`${metaTitle} | Lwart`} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={`https://www.lwart.com.br/blog/${post.slug}/`} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:secure_url" content={metaImage} />
      <meta property="og:image:alt" content={post.sharingCard ? post.sharingCard.description : ''} />
      <meta property="og:image:type" content={post.sharingCard ? post.sharingCard.localFile.internal.mediaType : ''} />
      <meta property="og:image:width" content={post.sharingCard ? post.sharingCard.localFile.childImageSharp.gatsbyImageData.presentationWidth : ''} />
      <meta property="og:image:height" content={post.sharingCard ? post.sharingCard.localFile.childImageSharp.gatsbyImageData.presentationHeight : ''} />
      <meta
        property="og:description"
        content={`${metaDescription}`}
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.lwart.com.br/blog/${post.slug}/"
            },
            "headline": "${metaTitle}",
            "description": "${metaDescription}",
            "image": [
              "${metaImage}"
            ],
            "datePublished": "${post.date}",
            "dateModified": "${post.updatedAt}",
            "author": {
              "@type": "Person",
              "name": "${post.author.nome}"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Lwart",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.lwart.com.br/blog.png"
              }
            }
          }
        `}
      </script>
    </Helmet>
    <header>
      <div className="d-flex align-items-center mb-5">
        {/* <Img fluid={post.author.foto.localFile.childImageSharp.fluid} className="rounded-circle author-image" /> */}
        <span className="d-flex justify-content-between">
          <Link to={`/profile/${post.author.slug}`} className="text-dark pr-2" >{post.author.nome}</Link>
          <span className="pr-2">·</span>
          <span className="text-muted font-size-09 pr-2">{post.date}</span>
          <span className="pr-2">·</span>
          <span className="text-muted font-size-09">{post.readTime}</span>
        </span>
      </div>

      <h1 className="line-height-normal letter-space-normal font-weight-bold mb-0">{post.title}</h1>
      <p className="font-size-1125 py-4">{post.description.description}</p>
    </header>
  </>
}

const BlogBody = (data) => {
  console.log('data:', data)
  const options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <figure>
            <div className='d-none d-lg-flex justify-content-center'>
              <GatsbyImage className='blog-images' image={node.data.target.localFile.childImageSharp.gatsbyImageData} alt={node.data.target.description} />
            </div>
            <div className='d-flex d-lg-none justify-content-center'>
              <GatsbyImage className='blog-images blog-images-mobile' image={node.data.target.localFile.childImageSharp.gatsbyImageData} alt={node.data.target.description} />
            </div>
            <figcaption className='text-gray text-center'>{node.data.target.description}</figcaption>
          </figure>

        )
      },
      paragraph: (node, children) => {
        let youtubeMatch = null
        node.content.map((content) => {
          if (content.nodeType === 'hyperlink') {
            youtubeMatch = /https:\/\/www\.youtube\.com\/watch\?v=([A-Za-z0-9_-]+)/.exec(content.data.uri)
          }
        })

        if (youtubeMatch) {
          const youtubeVideoId = youtubeMatch[1]

          return (
            <div>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )
        }

        return <p>{children}</p>
      }
    }
  }

  return (
    <>
      <figure>
        <GatsbyImage className='d-lg-block d-none banner-blog-size' image={data.image} alt={data.alt} />
        <GatsbyImage className='d-block d-lg-none' image={data.image} alt={data.alt} />
        <figcaption className='text-gray text-center'>{data.alt}</figcaption>
      </figure>
      <div className="mt-4 mb-0 blog-typography">
        {renderRichText(data.content, options)}
      </div>
    </>
  )
}

const BlogComments = (disqusProps) => (
  <>
    <div className="container mt-5 blog-typography">
      <h2>Comentários</h2>
      <DiscussionEmbed {...disqusProps} />
    </div>
  </>
)

const params = {
  slidesPerView: 1,
  spaceBetween: 10,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  }
}

const LatestPosts = ({ allPosts, currentPostSlug }) => {
  const excludedSlugs = [
    currentPostSlug,
    'lwart-will-become-the-second-largest-re-refinery-in-the-world'
  ]

  const filteredPosts = allPosts.nodes.filter(post => !excludedSlugs.includes(post.slug))

  const shuffledPosts = filteredPosts.sort(() => 0.5 - Math.random()).slice(0, 6)

  return (
    <div className="card px-lg-5 px-md-0 border-0">
      <header className="mt-4">
        <h2 className="font-size-1125 text-muted" >Leia Mais</h2>
      </header>

      <div className="row d-lg-flex d-none">

        {shuffledPosts.map((post, index) => {
          return (
            <div className="col-md-4 mb-4" key={`post-${index}`}>
              <div className="card custom-card">
                <GatsbyImage
                  image={post.cardImage.localFile.childImageSharp.gatsbyImageData}
                  alt={post.cardImage.description} />
                <div className="card-body">
                  <Link to={'/blog/' + post.slug} className="text-dark font-weight-bold">{post.title}</Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div id="swiper-mobile-blog" className="d-lg-none d-block">
        <Swiper {...params}>
          {shuffledPosts.map((post, index) => {
            return (
              <div className="card" key={`post-${index}`}>
                <GatsbyImage
                  image={post.cardImage.localFile.childImageSharp.gatsbyImageData}
                  alt={post.cardImage.description} />
                <div className="card-body">
                  <Link to={'/blog/' + post.slug} className="text-dark font-weight-bold">{post.title}</Link>
                </div>
              </div>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

const BtnContainer = ({ post }) => {
  const url = `https://www.lwart.com.br/blog/${post.slug}`
  return (
    <>
      <div className='row mt-3'>
        <div className='col'>
          <p>Compartilhe nas redes sociais:</p>
        </div>
      </div>
      <div className='row mb-3'>
        <a href={`https://www.facebook.com/sharer.php?u=${url}`}
          target="_blank" rel="noopener noreferrer"
          className='col-md-4 col-lg-3 col-xl-2 text-decoration-none'>
          <div className='content-socials_posts darkblue d-flex jusitfy-content-center align-items-center px-2 mb-3 mb-md-0'>
            <div>
              <img src={facebook} />
            </div>
            <span className='ml-3'>facebook</span>
          </div>
        </a>
        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          target="_blank" rel="noopener noreferrer"
          className='col-md-4 col-lg-3 col-xl-2 text-decoration-none'>
          <div className='content-socials_posts blue d-flex jusitfy-content-center align-items-center px-2 mb-3 mb-md-0'>
            <div>
              <img src={linkedin} />
            </div>
            <span className='ml-3'>linkedin</span>
          </div>
        </a>
        <a href={`https://twitter.com/intent/tweet?url=${url}&text=${post.sharingTitle ? post.sharingTitle : post.title}&hashtags=#${post.sharingTags ? post.sharingTags.join(', ') : post.tags.join(', ')}`}
          target="_blank" rel="noopener noreferrer"
          className='col-md-4 col-lg-3 col-xl-2 text-decoration-none'>
          <div className='content-socials_posts lightblue d-flex jusitfy-content-center align-items-center px-2'>
            <div>
              <img src={twitter} />
            </div>
            <span className='ml-3'>twitter</span>
          </div>
        </a>
      </div>
      <div className='row'>
        <a href={`https://www.pinterest.com/pin/create/link/?url=${url}`}
          target="_blank" rel="noopener noreferrer"
          className='col-md-4 col-lg-3 col-xl-2 text-decoration-none'>
          <div className='content-socials_posts darkred d-flex jusitfy-content-center align-items-center px-2 mb-3 mb-md-0'>
            <div>
              <img src={pinterest} />
            </div>
            <span className='ml-2'>pinterest</span>
          </div>
        </a>
        <a href={`https://api.whatsapp.com/send?text=${post.sharingTitle ? post.sharingTitle : post.title}%20${url}`}
          target="_blank" rel="noopener noreferrer"
          className='col-md-4 col-lg-3 col-xl-2 text-decoration-none'>
          <div className='content-socials_posts green d-flex jusitfy-content-center align-items-center px-2 mb-3 mb-md-0'>
            <div>
              <img src={whatsapp} />
            </div>
            <span className='ml-2'>whatsapp</span>
          </div>
        </a>
      </div>
    </>
  )
}

const BlogPost = ({ data }) => {
  const disqusProps = {
    shortname: 'lwart',
    config: {
      identifier: data.post.id,
      title: data.post.title
    }
  }

  return <>
    <section className="full bg-lwart-darkblue m-0">
      <div className="container bg-white px-3 py-5">
        <div className="card rounded-0 px-lg-5 px-3 py-4">
          <BlogHeader {...data.post} />
          <BlogBody content={data.post.content} image={data.post.cardImage.localFile.childImageSharp.gatsbyImageData} alt={data.post.cardImage.description} />
          <BtnContainer post={data.post} />
          <BlogComments {...disqusProps} />
        </div>
        <LatestPosts
          allPosts={data.allPosts}
          currentPostSlug={data.post.slug}
        />
      </div>
    </section>
  </>
}

export default BlogPost
export const query = graphql`query ($Slug: String!) {
  post: contentfulBlogPost(slug: {eq: $Slug}) {
    id
    title
    slug
    description {
      description
    }
    metaTitle
    metaDescription {
      metaDescription
    }
    tags
    readTime
    date(formatString: "DD/MM/YYYY")
    updatedAt
    content {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
            url
          }
          title
          description
        }
      }
    }
    author {
      id
      nome
      slug
      foto {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
    cardImage {
      file {
        details {
          image {
            width
            height
          }
        }
      }
      localFile {
        publicURL
      }
      description
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 740, height: 420, layout: CONSTRAINED)
        }
      }
    }
    sharingTitle
    sharingTags
    sharingCard {
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        internal {
          mediaType
        }
        publicURL
      }
      description
    }
  }
  allPosts: allContentfulBlogPost(filter: {node_locale: {eq: "pt-BR"}}) {
    nodes {
      title
      slug
      cardImage {
        description
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 455, height: 300, layout: CONSTRAINED)
          }
        }
      }
    }
  }
}
`
